const convertCountryNameToInitials = () => {
  switch (window.country_name) {
    case 'United States':
      return 'US';
    case 'United Kingdom':
      return 'UK';
    case 'Ireland':
      return 'IE';
    default:
      return window.country_name;
  }
};

const getLangAttribute = () => {
  const htmlElement = document.querySelector('html');
  return htmlElement ? htmlElement.getAttribute('lang') : null;
};

const splitLangCode = () => {
  const langAttribute = getLangAttribute();
  return langAttribute ? langAttribute.split('-')[1] : null;
};

const Store = {
  name: window.store,
  country: convertCountryNameToInitials() || splitLangCode() || 'US',
  isUnitedKingdom() {
    return this.country === 'UK';
  },
  isIreland() {
    return this.country === 'IE';
  },
  isUnitedStates() {
    return this.country === 'US';
  },
  isStore(storeName) {
    return typeof this.name === 'string' && this.name.toLowerCase() === storeName.toLowerCase();
  },
  isWalmart() {
    return this.isStore('walmart');
  },
  isAsda() {
    return this.isStore('asda');
  }
  
};

export default Store;
